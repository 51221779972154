.fixedWhatsapp {
	@apply z-[500] fixed bottom-[16px] right-[16px] md:bottom-[24px] md:right-[24px];

	.notification {
		@apply flex items-center justify-center w-[20px] h-[20px] bg-[#d2370d] text-white rounded-full pointer-events-none text-[12px];
		@apply absolute -top-[5px];
	}

	a:link,
	a:visited {
		@apply relative inline-block p-[8px] rounded-full;
		background-color: #25d366;
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
		transition: background-color 0.3s;
		&::after {
			content: '';
			@apply z-[-1] absolute w-[140%] h-[140%] left-[50%] top-[50%] translate-y-[-50%] rounded-full;
			background-color: #25d366;

			animation: breath 2s infinite;
			animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
			transition: background-color 0.3s;
		}

		&:hover {
			@apply bg-[#21bb59];

			&::after {
				@apply bg-[#21bb59];
			}
		}
		svg {
			@apply w-[3.2rem] h-[3.2rem] fill-secondary;
			transition: fill 0.3s;
			transition-delay: 0.2s;
		}
	}
}

@keyframes breath {
	from {
		opacity: 1;
		transform: translate(-50%, -50%) scale(0);
	}

	to {
		opacity: 0;
		transform: translate(-50%, -50%) scale(1);
	}
}
