.section-heading {
	@apply relative flex flex-col items-center gap-[0.8rem] mb-[48px] text-center;
	@apply md:mb-[54px];
	@apply lg:mb-[64px];
	animation: appear linear;
	animation-timeline: view();
	animation-fill-mode: both;
	animation-range-start: 0;
	animation-range-end: 100px;

	&::after {
		content: '';
		@apply absolute bottom-[-1.4rem] left-[50%] w-[70px] h-[4px] bg-colorLayoutComplementary rounded-full;
		transform: translateX(calc(-50% - 10px));
	}
	&::before {
		content: '';
		@apply absolute bottom-[-1.4rem] left-[50%] w-[10px] h-[4px] bg-colorLayoutComplementary rounded-full;
		transform: translateX(calc(-50% + 40px));
	}
	h2 {
		@apply text-3xl font-semibold max-w-[40ch] leading-tight;
	}
	p {
		@apply text-xl leading-tight max-w-[40ch];
	}

	// funcionalidad

	&.white {
		@apply text-secondary;
		&::after {
			@apply bg-secondary;
		}

		&::before {
			@apply bg-secondary;
		}
	}
}
